'use client';
import {faCircleXmark} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/nextjs';
import {useTranslations} from 'next-intl';
import React, {useEffect} from 'react';

import {Typography} from '@/components/typography/typography';

import {BackgroundGlow} from '../background-glow/background-glow';

import styles from './error.module.scss';

type PropsType = {
    error: Error & {digest?: string};
};

export function Error(props: PropsType): React.JSX.Element {
    const {error} = props;

    const t = useTranslations('Error');

    useEffect(() => {
        Sentry.captureException(error);
    }, [error]);

    return (
        <>
            <FontAwesomeIcon className={styles.Error_iconWrapper} icon={faCircleXmark} />

            <hgroup className={styles.Error_hgroup}>
                <Typography.Title className={styles.Error_title}>{t('title')}</Typography.Title>

                <Typography.Text className={styles.Error_description} type="secondary">
                    {t('description')}
                </Typography.Text>
            </hgroup>

            <button onClick={() => location.reload()}>{t('reloadButton')}</button>

            <BackgroundGlow color="#19aa1e" />
        </>
    );
}
