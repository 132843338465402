import type {CSSProperties} from 'react';

import styles from './background-glow.module.scss';

type PropsType = {
    color: string;
};

export function BackgroundGlow(props: PropsType): React.JSX.Element {
    const {color} = props;

    return <div className={styles.BackgroundGlow} style={{'--color-primary': color} as CSSProperties} />;
}
